// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import "./Header.css";

// function Header() {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleNavbar = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <header className="custom-header fixed-top">
//       <nav className="navbar navbar-expand-md navbar-white">
//         <div className="container-xl">
//           <Link className="navbar-brand" to="/">
//             <img
//               src="/logo.png"
//               alt="Logo"
//               className="logo img-fluid"
//               style={{
//                 height: "50px",
//               }}
//             />
//           </Link>
//           <button
//             className="navbar-toggler"
//             type="button"
//             onClick={toggleNavbar}
//             aria-controls="navbarNav"
//             aria-expanded={isOpen ? "true" : "false"}
//             aria-label="Toggle navigation"
//             style={{
//               borderRadius: "0px",
//               boxShadow: "none",
//             }}
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>
//           <div
//             className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
//             id="navbarNav"
//           >
//             <ul className="navbar-nav mx-auto">
//               <li className="nav-item dropdown">
//                 <a
//                   className="nav-link dropdown-toggle"
//                   href="#about"
//                   id="navbarDropdown"
//                   role="button"
//                   data-bs-toggle="dropdown"
//                   aria-expanded="false"
//                 >
//                   About Us
//                 </a>
//                 <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
//                   <li>
//                     <Link
//                       onClick={toggleNavbar}
//                       className="dropdown-item"
//                       to="/about-us"
//                     >
//                       Who We Are
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       onClick={toggleNavbar}
//                       className="dropdown-item"
//                       to="/about#team"
//                     >
//                       Our Team
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       onClick={toggleNavbar}
//                       className="dropdown-item"
//                       to="/about#vision"
//                     >
//                       Our Vision
//                     </Link>
//                   </li>
//                 </ul>
//               </li>
//               <li className="nav-item dropdown">
//                 <a
//                   className="nav-link dropdown-toggle"
//                   href="#about"
//                   id="navbarDropdown"
//                   role="button"
//                   data-bs-toggle="dropdown"
//                   aria-expanded="false"
//                 >
//                   Our Work
//                 </a>
//                 <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
//                   <li>
//                     <Link
//                       onClick={toggleNavbar}
//                       className="dropdown-item"
//                       to="/about-us"
//                     >
//                       Who We Are
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       onClick={toggleNavbar}
//                       className="dropdown-item"
//                       to="/about#team"
//                     >
//                       Our Team
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       onClick={toggleNavbar}
//                       className="dropdown-item"
//                       to="/about#vision"
//                     >
//                       Our Vision
//                     </Link>
//                   </li>
//                 </ul>
//               </li>
//               <li className="nav-item dropdown">
//                 <a
//                   className="nav-link dropdown-toggle"
//                   href="#about"
//                   id="navbarDropdown"
//                   role="button"
//                   data-bs-toggle="dropdown"
//                   aria-expanded="false"
//                 >
//                   News & Stories
//                 </a>
//                 <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
//                   <li>
//                     <Link
//                       onClick={toggleNavbar}
//                       className="dropdown-item"
//                       to="/about-us"
//                     >
//                       Who We Are
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       onClick={toggleNavbar}
//                       className="dropdown-item"
//                       to="/about#team"
//                     >
//                       Our Team
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       onClick={toggleNavbar}
//                       className="dropdown-item"
//                       to="/about#vision"
//                     >
//                       Our Vision
//                     </Link>
//                   </li>
//                 </ul>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link" to="/contact-us">
//                   Contact
//                 </Link>
//               </li>
//             </ul>
//             <div className="d-flex flex-row">
//               <Link
//                 to="/donate"
//                 className="btn btn-outline-light donate-btn flex-fill me-2"
//                 style={{
//                   backgroundColor: "#00715D",
//                   color: "#FFFFFF",
//                   borderRadius: "0px",
//                   padding: "10px 30px",
//                 }}
//               >
//                 Donate
//               </Link>
//               <Link
//                 to="/volunteer"
//                 className="btn btn-outline-light volunteer-btn flex-fill"
//                 style={{
//                   backgroundColor: "#00715D",
//                   color: "#FFFFFF",
//                   borderRadius: "0px",
//                   padding: "10px 30px",
//                 }}
//               >
//                 Volunteer
//               </Link>
//             </div>
//           </div>
//         </div>
//       </nav>
//     </header>
//   );
// }

// export default Header;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false); // This closes the modal on link click
  };

  return (
    <header
      className="custom-header fixed-top"
      style={{
        transition: "all 0.3s ease",
      }}
    >
      <nav className="navbar navbar-expand-md navbar-white">
        <div className="container-fluid px-3 px-md-5">
          <Link className="navbar-brand" to="/">
            <img
              src="/logo.png"
              alt="Logo"
              className="logo img-fluid"
              style={{
                height: "50px",
              }}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
            aria-controls="navbarNav"
            aria-expanded={isOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            style={{
              borderRadius: "0px",
              boxShadow: "none",
              border: "0px",
            }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
            id="navbarNav"
          >
            <ul className="navbar-nav mx-auto">
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#about"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  About Us
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link
                      onClick={closeNavbar}
                      className="dropdown-item"
                      to="/about-us"
                    >
                      Who We Are
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={closeNavbar}
                      className="dropdown-item"
                      to="/about-us"
                    >
                      Mission & Vision
                    </Link>
                  </li>
                </ul>
              </li> */}
              <li className="nav-item">
                <Link onClick={closeNavbar} className="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={closeNavbar} className="nav-link" to="/about-us">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={closeNavbar} className="nav-link" to="/projects">
                  Projects
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  onClick={closeNavbar}
                  className="nav-link"
                  to="/news-and-stories"
                >
                  News & Stories
                </Link>
              </li> */}
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#our-work"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Our Work
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link
                      onClick={closeNavbar}
                      className="dropdown-item"
                      to="/about#crisis"
                    >
                      Crisis
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={closeNavbar}
                      className="dropdown-item"
                      to="/about#food&water"
                    >
                      Food & Water
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={closeNavbar}
                      className="dropdown-item"
                      to="/about#health"
                    >
                      Health
                    </Link>
                  </li>
                </ul>
              </li> */}
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#latestandstories"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  News & Stories
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link
                      onClick={closeNavbar}
                      className="dropdown-item"
                      to="/news#latest-stories"
                    >
                      Latest Stories
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={closeNavbar}
                      className="dropdown-item"
                      to="/news#news"
                    >
                      News
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={closeNavbar}
                      className="dropdown-item"
                      to="/news#health"
                    >
                      Health
                    </Link>
                  </li>
                </ul>
              </li> */}
              <li className="nav-item">
                <Link
                  onClick={closeNavbar}
                  className="nav-link"
                  to="/contact-us"
                >
                  Contact Us
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={closeNavbar} className="nav-link" to="/blogs">
                  Blogs
                </Link>
              </li>
            </ul>
            <div className="d-flex flex-row">
              <Link
                to="/donate"
                onClick={closeNavbar}
                className="btn btn-outline-light donate-btn flex-fill me-2"
                style={{
                  backgroundColor: "#00715D",
                  color: "#FFFFFF",
                  borderRadius: "50px",
                  padding: "10px 30px",
                }}
              >
                Donate
              </Link>
              <Link
                to="/volunteer"
                onClick={closeNavbar}
                className="btn btn-outline-light volunteer-btn flex-fill"
                style={{
                  backgroundColor: "#00715D",
                  color: "#FFFFFF",
                  borderRadius: "50px",
                  padding: "10px 30px",
                }}
              >
                Volunteer
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
