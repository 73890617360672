import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "bootstrap-icons/font/bootstrap-icons.css";

import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import News from "./pages/News/News";
import Projects from "./pages/Projects/Projects";
import Donate from "./pages/Donate/Donate";
import Volunteer from "./pages/Volunteer/Volunteer";
import Footer from "./components/Footer/Footer";
import MissionVision from "./pages/About/MissionVision";
import Story from "./pages/About/Story";
import Blogs from "./pages/Blogs/Blogs";
import BlogPost from "./pages/BlogPost/BlogPost";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/about-us/mission-vission" element={<MissionVision />} />
        <Route path="/about-us/our-story" element={<Story />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/news-and-stories" element={<News />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/volunteer" element={<Volunteer />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:id" element={<BlogPost />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
