import React from "react";
import "./News.css";
import { Link } from "react-router-dom";

import Image from "../../assets/Images/Image-17.webp";

function News() {
  // Example news data
  const newsList = [
    {
      id: "the-latest-on-hurricane-milton-and-how-you-can-help/",
      title: "The latest on Hurricane Milton and how you can help",
      description:
        "As Hurricane Milton leaves millions without power and homes submerged in Florida, CARE is working with partners on the ground providing urgent aid and hope to those hardest hit.",
      image: Image,
    },
    {
      id: "the-latest-on-hurricane-milton-and-how-you-can-help/",
      title: "The latest on Hurricane Milton and how you can help",
      description:
        "As Hurricane Milton leaves millions without power and homes submerged in Florida, CARE is working with partners on the ground providing urgent aid and hope to those hardest hit.",
      image: Image,
    },
    {
      id: "the-latest-on-hurricane-milton-and-how-you-can-help/",
      title: "The latest on Hurricane Milton and how you can help",
      description:
        "As Hurricane Milton leaves millions without power and homes submerged in Florida, CARE is working with partners on the ground providing urgent aid and hope to those hardest hit.",
      image: Image,
    },
  ];

  return (
    <div
      className="container-fluid px-3 px-md-5"
      style={{
        marginTop: "100px",
      }}
    >
      <h2>Latest News & Stories</h2>
      <div className="row">
        {newsList.map((newsItem, index) => (
          <div className="col-md-4" key={index}>
            <div className="card mb-4 rounded-0">
              <img
                src={newsItem.image}
                className="card-img-top rounded-0"
                alt={newsItem.title}
              />
              <div className="card-body">
                <h5 className="card-title">{newsItem.title}</h5>
                <p className="card-text">{newsItem.description}</p>
                <Link
                  to={`news/${newsItem.id}`}
                  className="btn"
                  style={{
                    color: "#FFFFFF",
                    backgroundColor: "#00715D",
                    borderRadius: "0px",
                    width: "100%",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default News;
