import React from "react";
import "./Newsletter.css";

function Newsletter() {
  return (
    <div
      className="newsletter"
      style={{
        marginTop: "100px",
      }}
    >
      <div className="container-xl">
        {/* Row for the text and input form */}
        <div className="row align-items-center">
          {/* Left Column for Text */}
          <div className="col-lg-6 text-lg-start text-center mb-3 mb-lg-0">
            <h2 className="mb-3 text-white">Sign up for our Newsletter</h2>
            <p className="text-white">
              Stay in the loop with everything you need to know.
            </p>
          </div>

          {/* Right Column for Input and Button */}
          <div className="col-lg-6">
            <form className="d-flex justify-content-lg-end justify-content-center">
              <input
                type="email"
                className="form-control me-2"
                placeholder="Enter your email"
                required
                style={{
                  borderRadius: "50px",
                  boxShadow: "none",
                  border: "0px",
                  outline: "0px",
                  maxWidth: "300px",
                }}
              />
              <button
                className="btn"
                type="submit"
                style={{
                  backgroundColor: "#E17153",
                  padding: "10px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  borderRadius: "0px",
                  color: "#FFF",
                  border: "0px",
                }}
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
