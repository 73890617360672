import React from "react";
import Marquee from "react-fast-marquee";
import "./Gallery.css";
import { imageData } from "../../utils/data";

const Gallery = () => {
  return (
    <div className="gallery">
      <div className="container-md">
        <h4>
          We save lives and helps millions of people find the way to a better
          life globally.
        </h4>
      </div>
      <Marquee speed={50} gradient={false}>
        {imageData.map((image, index) => (
          <div key={index} className="gallery-item">
            <img src={image.src} alt={image.alt} />
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default Gallery;
