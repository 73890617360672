import React, { useEffect, useState } from "react";
import Banner from "../../components/Banner/Banner";
import Data from "../../components/News/News";

function News() {
  const [donationAmount, setDonationAmount] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Scroll to a specific section if the URL contains a hash
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, []);

  const handleNews = (e) => {
    e.preventDefault();
    alert(
      `Thank you for donating ${
        donationAmount ? `₦${donationAmount}` : selectedOption
      }!`
    );
  };

  return (
    <div>
      <Banner title="Our News" />
      {/* News */}
      <Data />
    </div>
  );
}

export default News;
