// import React from "react";

// function Footer() {
//   return (
//     <footer style={{ backgroundColor: "#00715D" }} className="text-white py-4">
//       <div className="container-xl">
//         <div className="row">
//           {/* Logo Section */}
//           <div className="col-md-4">
//             <img
//               src="logo.png" // Add the path to your logo image here
//               alt="Charity Logo"
//               className="img-fluid mb-3" // Makes the image responsive
//               style={{ maxWidth: "150px" }} // Limit the size of the logo
//             />
//             <p>
//               We are a nonprofit organization dedicated to helping those in
//               need. Our mission is to provide resources, support, and hope to
//               communities around the world.
//             </p>
//           </div>

//           {/* Contact Information */}
//           <div className="col-md-4">
//             <h5>Contact Information</h5>
//             <ul className="list-unstyled">
//               <li>
//                 <i className="bi bi-geo-alt-fill"></i> 123 Traffina Foundation
//                 Street, Kindness City
//               </li>
//               <li>
//                 <i className="bi bi-telephone-fill"></i> +123 456 7890
//               </li>
//               <li>
//                 <i className="bi bi-envelope-fill"></i>{" "}
//                 contact@Traffina Foundation.org
//               </li>
//             </ul>
//           </div>

//           {/* Social Media Links */}
//           <div className="col-md-4">
//             <h5>Follow Us</h5>
//             <ul className="list-unstyled d-flex">
//               <li className="me-3">
//                 <a href="#" className="text-white">
//                   <i
//                     className="bi bi-facebook"
//                     style={{ fontSize: "1.5rem" }}
//                   ></i>
//                 </a>
//               </li>
//               <li className="me-3">
//                 <a href="#" className="text-white">
//                   <i
//                     className="bi bi-twitter"
//                     style={{ fontSize: "1.5rem" }}
//                   ></i>
//                 </a>
//               </li>
//               <li className="me-3">
//                 <a href="#" className="text-white">
//                   <i
//                     className="bi bi-instagram"
//                     style={{ fontSize: "1.5rem" }}
//                   ></i>
//                 </a>
//               </li>
//             </ul>
//           </div>
//         </div>

//         {/* Copyright */}
//         <div className="text-center mt-3">
//           <p className="mb-0">
//             &copy; 2024 Traffina Foundation. All rights reserved.
//           </p>
//         </div>
//       </div>
//     </footer>
//   );
// }

// export default Footer;

import React from "react";

function Footer() {
  return (
    <footer style={{ backgroundColor: "#090015" }} className="text-white py-5">
      <div className="container-xl">
        <div className="row">
          {/* Logo & Description Section */}
          <div className="col-lg-4 col-md-6 mb-4">
            <img
              src="logo.png" // Add the path to your logo image here
              alt="Charity Logo"
              className="img-fluid mb-3"
              style={{ maxWidth: "180px" }} // Increased logo size for better visibility
            />
            <p className="text-white" style={{ fontSize: "1rem" }}>
              We are a nonprofit organization dedicated to helping those in
              need. Our mission is to provide resources, support, and hope to
              communities worldwide.
            </p>
            {/* Social Media Links */}
            <div className="col-lg-4 col-md-12">
              <h5 className="mb-4" style={{ fontWeight: "bold" }}>
                Follow Us
              </h5>
              <ul className="list-unstyled d-flex justify-content-start">
                <li className="me-4">
                  <a
                    href="https://www.facebook.com/Traffinafoundation/"
                    className="text-white"
                    target="_blank"
                    aria-label="Facebook"
                  >
                    <i
                      className="bi bi-facebook"
                      style={{ fontSize: "1.8rem" }}
                    ></i>
                  </a>
                </li>
                <li className="me-4">
                  <a
                    href="https://www.twitter.com/Traffinafoundation/"
                    className="text-white"
                    target="_blank"
                    aria-label="Twitter"
                  >
                    <i
                      className="bi bi-twitter"
                      style={{ fontSize: "1.8rem" }}
                    ></i>
                  </a>
                </li>
                <li className="me-4">
                  <a
                    href="https://www.instagram.com/Traffinafoundation/"
                    className="text-white"
                    target="_blank"
                    aria-label="Instagram"
                  >
                    <i
                      className="bi bi-instagram"
                      style={{ fontSize: "1.8rem" }}
                    ></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/Traffinafoundation/"
                    className="text-white"
                    target="_blank"
                    aria-label="LinkedIn"
                  >
                    <i
                      className="bi bi-linkedin"
                      style={{ fontSize: "1.8rem" }}
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Contact Information */}
          <div className="col-lg-4 col-md-6 mb-4">
            <h5 className="mb-4" style={{ fontWeight: "bold" }}>
              Contact Information
            </h5>
            <ul
              className="list-unstyled text-white"
              style={{ fontSize: "1rem" }}
            >
              <li className="mb-2">
                <i className="bi bi-geo-alt-fill me-2"></i>
                No 3 Quarry road , beside Vetinary clinic , Jigo Old road, Bwari
                Abuja, Nigeria
              </li>
              <li className="mb-2">
                <i className="bi bi-telephone-fill me-2"></i>
                +234 8069354018, +234 909 620169, +234 8033464577{" "}
              </li>
              <li className="mb-2">
                <i className="bi bi-envelope-fill me-2"></i>
                traffinafoundation@yahoo.com
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="col-lg-4 col-md-6 mb-4">
            <h5 className="mb-4" style={{ fontWeight: "bold" }}>
              Contact Information
            </h5>
            <ul
              className="list-unstyled text-white"
              style={{ fontSize: "1rem" }}
            >
              <li className="mb-2">
                <i className="bi bi-geo-alt-fill me-2"></i>
                16600 NorthwestAvenue 73012, United State
              </li>
              <li className="mb-2">
                <i className="bi bi-telephone-fill me-2"></i>
                +405 506 8655
              </li>
              <li className="mb-2">
                <i className="bi bi-envelope-fill me-2"></i>
                traffinafoundation.us@gmail.com
              </li>
            </ul>
          </div>
        </div>

        {/* Divider */}
        <hr className="border-top border-light my-4" />

        {/* Copyright Section */}
        <div className="text-center">
          <p className="mb-0 text-white">
            &copy; 2024 Traffina Foundation. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
