import React, { useEffect, useState } from "react";
import Banner from "../../components/Banner/Banner";

const Volunteer = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Scroll to a specific section if the URL contains a hash
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, []);

  // State for form inputs
  const [formData, setFormData] = useState({
    lastName: "",
    firstName: "",
    middleName: "",
    dateOfBirth: "",
    age: "",
    sex: "",
    raceEthnicity: "",
    currentAddress: "",
    cityStateZip: "",
    countryOfResidence: "",
    homeNumber: "",
    mobileNumber: "",
    email: "",
    currentStatus: "",
    areaOfStudy: "",
    currentEmployer: "",
    specialInterests: [],
    reasonForVolunteering: "",
    references: [
      { name: "", relation: "", phone: "" },
      { name: "", relation: "", phone: "" },
    ],
    agreement: false,
  });

  // Update the state for inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "specialInterests") {
      // For checkboxes, we manage the array of selected interests
      setFormData((prevData) => {
        const updatedInterests = prevData.specialInterests.includes(value)
          ? prevData.specialInterests.filter((interest) => interest !== value)
          : [...prevData.specialInterests, value];
        return { ...prevData, specialInterests: updatedInterests };
      });
    } else if (name.startsWith("reference")) {
      const index = parseInt(name.split("-")[1], 10);
      setFormData((prevData) => {
        const updatedReferences = [...prevData.references];
        updatedReferences[index][name.split("-")[2]] = value;
        return { ...prevData, references: updatedReferences };
      });
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  // Handle agreement checkbox
  const handleAgreementChange = () => {
    setFormData((prevData) => ({
      ...prevData,
      agreement: !prevData.agreement,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  };

  return (
    <>
      <Banner
        title="Volunteer Application"
        description="Support us with what you have"
      />
      <div
        className="container-xl px-3 px-md-5"
        style={{ marginTop: "50px", marginBottom: "50px" }}
      >
        <p>
          Dear Prospective Volunteer: <br />
          Thank you for your interest in volunteering with Traffina Foundation
          for Community Health. Our vital work and success are made possible by
          our staff, and our current and future VOLUNTEERS like you! There are
          many volunteer opportunities available for both individuals and groups
          that help support the cause and mission of preventing maternal
          newborn/child mortality and morbidity rate in your community.
        </p>
        <p>
          If you are interested in becoming a volunteer, please take a few
          minutes to fill out this volunteer application form. All information
          given on this form will be kept confidential, and will be used only by
          Traffina Foundation for Community Health.
        </p>
        <form className="mt-4" onSubmit={handleSubmit}>
          {/* General Information */}
          <h4>Section I: General Information</h4>
          <div className="mb-3">
            <label className="form-label">Last Name</label>
            <input
              type="text"
              className="form-control"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">First Name</label>
            <input
              type="text"
              className="form-control"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Middle Name</label>
            <input
              type="text"
              className="form-control"
              name="middleName"
              value={formData.middleName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3 row">
            <div className="col-md-6">
              <label className="form-label">Date of Birth</label>
              <input
                type="date"
                className="form-control"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Age</label>
              <input
                type="number"
                className="form-control"
                name="age"
                value={formData.age}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Sex</label>
            <div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="sex"
                  value="M"
                  checked={formData.sex === "M"}
                  onChange={handleChange}
                />
                <label className="form-check-label">Male</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="sex"
                  value="F"
                  checked={formData.sex === "F"}
                  onChange={handleChange}
                />
                <label className="form-check-label">Female</label>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Race/Ethnicity</label>
            <select
              className="form-select"
              name="raceEthnicity"
              value={formData.raceEthnicity}
              onChange={handleChange}
            >
              <option>African</option>
              <option>African-American</option>
              <option>Asian</option>
              <option>Caucasian</option>
              <option>Hispanic</option>
            </select>
          </div>

          <div className="mb-3">
            <label className="form-label">Current Address</label>
            <input
              type="text"
              className="form-control"
              placeholder="Street Address"
              name="currentAddress"
              value={formData.currentAddress}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">City, State, Zip</label>
            <input
              type="text"
              className="form-control"
              placeholder="City, State, Zip"
              name="cityStateZip"
              value={formData.cityStateZip}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Country of Residence</label>
            <input
              type="text"
              className="form-control"
              name="countryOfResidence"
              value={formData.countryOfResidence}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3 row">
            <div className="col-md-6">
              <label className="form-label">Home Number</label>
              <input
                type="tel"
                className="form-control"
                name="homeNumber"
                value={formData.homeNumber}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Mobile Number</label>
              <input
                type="tel"
                className="form-control"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>

          {/* Employment and Educational Background */}
          <h4>Section II: Employment and Educational Background</h4>
          <div className="mb-3">
            <label className="form-label">Current Status</label>
            <select
              className="form-select"
              name="currentStatus"
              value={formData.currentStatus}
              onChange={handleChange}
            >
              <option>Employed</option>
              <option>Unemployed</option>
              <option>Student</option>
            </select>
          </div>

          <div className="mb-3">
            <label className="form-label">Area of Study</label>
            <input
              type="text"
              className="form-control"
              name="areaOfStudy"
              value={formData.areaOfStudy}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Current Employer</label>
            <input
              type="text"
              className="form-control"
              name="currentEmployer"
              value={formData.currentEmployer}
              onChange={handleChange}
            />
          </div>

          {/* Special Interests */}
          <h4>Section III: Special Interests</h4>
          <div className="mb-3">
            <label className="form-label">
              What special interests do you have? (Select all that apply)
            </label>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name="specialInterests"
                value="Health"
                checked={formData.specialInterests.includes("Health")}
                onChange={handleChange}
              />
              <label className="form-check-label">Health</label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name="specialInterests"
                value="Education"
                checked={formData.specialInterests.includes("Education")}
                onChange={handleChange}
              />
              <label className="form-check-label">Education</label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name="specialInterests"
                value="Community Service"
                checked={formData.specialInterests.includes(
                  "Community Service"
                )}
                onChange={handleChange}
              />
              <label className="form-check-label">Community Service</label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name="specialInterests"
                value="Other"
                checked={formData.specialInterests.includes("Other")}
                onChange={handleChange}
              />
              <label className="form-check-label">Other</label>
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Reason for Volunteering</label>
            <textarea
              className="form-control"
              name="reasonForVolunteering"
              value={formData.reasonForVolunteering}
              onChange={handleChange}
              rows="3"
            ></textarea>
          </div>

          {/* References */}
          <h4>Section IV: References</h4>
          {formData.references.map((reference, index) => (
            <div key={index} className="mb-3">
              <h5>Reference {index + 1}</h5>
              <div className="mb-2">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name={`reference-${index}-name`}
                  value={reference.name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <label className="form-label">Relation</label>
                <input
                  type="text"
                  className="form-control"
                  name={`reference-${index}-relation`}
                  value={reference.relation}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <label className="form-label">Phone</label>
                <input
                  type="tel"
                  className="form-control"
                  name={`reference-${index}-phone`}
                  value={reference.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
          ))}

          {/* Agreement */}
          <div className="form-check mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              name="agreement"
              checked={formData.agreement}
              onChange={handleAgreementChange}
            />
            <label className="form-check-label">
              I agree to the terms and conditions.
            </label>
          </div>

          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#00715D",
              color: "#FFFFFF",
              borderRadius: "50px",
              padding: "10px 50px",
              marginTop: "50px",
            }}
          >
            Submit Application
          </button>
        </form>
      </div>
    </>
  );
};

export default Volunteer;
