import React, { useEffect, useState } from "react";
import Banner from "../../components/Banner/Banner";

function Donate() {
  const [donationAmount, setDonationAmount] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    email: "",
    phone: "",
    creditCardNumber: "",
    expirationMonth: "",
    expirationYear: "",
    cvv: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDonate = (e) => {
    e.preventDefault();
    alert(
      `Thank you for donating $${
        donationAmount ? `₦${donationAmount}` : selectedOption
      }!\n\nBilling Information:\n${JSON.stringify(formData, null, 2)}`
    );
  };

  return (
    <div>
      <Banner title="Donate Now" description="Help Those in Need" />
      <div className="container-xl py-5">
        <form onSubmit={handleDonate}>
          {/* Billing Information */}
          <h4>Billing Information</h4>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">First Name:</label>
              <input
                type="text"
                className="form-control"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Last Name:</label>
              <input
                type="text"
                className="form-control"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">Email Address:</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Phone Number:</label>
              <input
                type="tel"
                className="form-control"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-3">
              <label className="form-label">Country:</label>
              <input
                type="country"
                className="form-control"
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label">City:</label>
              <input
                type="text"
                className="form-control"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label">State/Province:</label>
              <input
                type="text"
                className="form-control"
                name="state"
                value={formData.state}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label">ZIP/Postal Code:</label>
              <input
                type="text"
                className="form-control"
                name="zip"
                value={formData.zip}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Payment Information */}
          <h4>Payment Information</h4>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">Card Number:</label>
              <input
                type="text"
                className="form-control"
                name="creditCardNumber"
                value={formData.creditCardNumber}
                onChange={handleChange}
                required
              />
            </div>

            <div className="col-md-6 mb-3">
              <label className="form-label">Expiration Month:</label>
              <input
                type="text"
                className="form-control"
                name="expirationMonth"
                value={formData.expirationMonth}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Expiration Year:</label>
              <input
                type="text"
                className="form-control"
                name="expirationYear"
                value={formData.expirationYear}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">CVV Number:</label>
              <input
                type="text"
                className="form-control"
                name="expirationYear"
                value={formData.expirationYear}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">Expiration Month:</label>
              <select
                className="form-select"
                name="expirationMonth"
                value={formData.expirationMonth}
                onChange={handleChange}
                required
              >
                <option value="">Select month</option>
                {[...Array(12)].map((_, index) => (
                  <option key={index} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Expiration Year:</label>
              <select
                className="form-select"
                name="expirationYear"
                value={formData.expirationYear}
                onChange={handleChange}
                required
              >
                <option value="">Select year</option>
                {[2024, 2025, 2026, 2027, 2028, 2029, 2030].map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">CVV Number:</label>
              <input
                type="text"
                className="form-control"
                name="cvv"
                value={formData.cvv}
                onChange={handleChange}
                required
              />
            </div>
          </div> */}

          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#00715D",
              color: "#FFFFFF",
              borderRadius: "50px",
              padding: "10px 50px",
              marginTop: "50px",
            }}
          >
            Donate Now
          </button>
        </form>
      </div>
    </div>
  );
}

export default Donate;
