import React, { useState, useEffect } from "react";
import "./Contact.css";
import Banner from "../../components/Banner/Banner";

function Contact() {
  // Form state
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  // Scroll to the top on component load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Scroll to specific section if URL contains hash
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, []);

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Form submission logic here (e.g., send data to API or handle validation)
    console.log("Form submitted:", formData);
    // Optionally, reset form after submission
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      message: "",
    });
  };

  return (
    <>
      <Banner
        title="Contact Us"
        description="Any question or remarks? Just write us a message!"
      />
      <div className="container-xl px-3 px-md-5 my-5">
        <form className="row" onSubmit={handleSubmit}>
          {/* First Name */}
          <div className="col-md-6 mb-3">
            <input
              type="text"
              name="firstName"
              className="form-control"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>

          {/* Last Name */}
          <div className="col-md-6 mb-3">
            <input
              type="text"
              name="lastName"
              className="form-control"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>

          {/* Email Address */}
          <div className="col-md-6 mb-3">
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Email Address"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          {/* Phone Number */}
          <div className="col-md-6 mb-3">
            <input
              type="tel"
              name="phoneNumber"
              className="form-control"
              placeholder="Phone Number"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>

          {/* Message */}
          <div className="col-md-12 mb-3">
            <textarea
              name="message"
              className="form-control"
              placeholder="Your Message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          {/* Submit Button */}
          <div className="col-md-12 text-center">
            <button
              type="submit"
              className="btn"
              style={{
                backgroundColor: "#00715D",
                color: "#FFFFFF",
                borderRadius: "50px",
                padding: "10px 100px",
              }}
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Contact;
