import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { blogsList } from "../../utils/data";
import "./BlogPost.css";

function BlogPost() {
  const { id } = useParams(); // Get the ID from the URL
  const blog = blogsList.find((post) => post.id === parseInt(id)); // Find the blog with the matching ID

  // Scroll to the top on component load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Scroll to specific section if URL contains hash
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, []);

  // Render loading message or blog not found message if blog is undefined
  if (!blog) {
    return <h2>Blog post not found</h2>;
  }

  return (
    <div className="blog-post">
      {/* Hero Section */}
      <div
        className="blog-hero"
        style={{ backgroundImage: `url(${blog.image})` }}
      >
        <div className="hero-overlay">
          <h1 className="blog-title">{blog.title}</h1>
        </div>
      </div>
      {/* Blog Content */}
      <div className="blog-content">
        <p className="blog-description">{blog.description}</p>
        <ul className="blog-data-list">
          {blog.data.map((point, index) => (
            <li key={index} className="blog-data-item">
              {point}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default BlogPost;
