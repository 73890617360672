import React from "react";
import "./Banner.css"; // Optional, if you want to add custom styles

function Banner({ title, description }) {
  return (
    <div
      className="container-fluid text-white text-center d-flex align-items-center justify-content-center flex-column" // Added flex-column for vertical alignment
      style={{
        height: "300px",
        marginTop: "100px",
        backgroundColor: "#00715D",
      }}
    >
      <h1
        className="display-4"
        style={{
          color: "#FFFFFF",
          fontWeight: "bold",
        }}
      >
        {title}
      </h1>
      <p className="fs-5" style={{ marginTop: "5px" }}>
        {description}
      </p>
    </div>
  );
}

export default Banner;
