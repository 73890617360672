import React, { useEffect } from "react";
import Banner from "../../components/Banner/Banner";

function MissionVision() {
  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Get the current URL
    const hash = window.location.hash;

    if (hash) {
      // Scroll to the specific section
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, []);
  return (
    <>
      <Banner
        title="Mission & Vision"
        // description="We save lives, defeat poverty, achieve social justice, and fight for women and children."
      />
      <div
        className="container-xl px-3 px-md-5"
        style={{
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        {/* Mission, Vision, and Additional Content */}
        <div className="mt-5">
          <h2>Our Mission</h2>
          <p>
            To provide a lead access to quality maternal newborn and child
            health services, to large populations without consideration for
            gender, age, sexual orientation, ethnicity, and givens of life.
          </p>

          <h2>Our Vision</h2>
          <p>
            A society where every mother and child survives the sacred act of
            childbirth irrespective of age and socio-economic status.
          </p>

          <h2>Those We Serve</h2>
          <ul>
            <li>
              Children, Women, and Young people (both in-and out of school) in
              various communities.
            </li>
            <li>Young People Living With HIV/AIDS</li>
            <li>Pregnant Women, New Moms, and Children</li>
            <li>People with Disability (PWD)</li>
            <li>Orphans and Vulnerable Children (OVC)</li>
            <li>Internally displaced populations</li>
          </ul>

          <h2>Our Objectives</h2>
          <ul>
            <li>
              Educating communities on prevention of all forms of violence
              against women and young girls in Nigeria, especially in
              post-conflict zones.
            </li>
            <li>
              Improving quality Maternal, newborn, and child healthcare across
              communities in Nigeria.
            </li>
            <li>
              Improving quality of care from pregnancy through the period of
              childbirth.
            </li>
            <li>
              Providing quality clean birth kits to pregnant women in rural
              communities to improve safe childbirth.
            </li>
            <li>
              Carrying out enlightenment programs focused on Sexual &amp;
              Reproductive Health Rights, HIV/AIDS care, drugs and substance
              abuse prevention.
            </li>
            <li>
              Strengthening capacity of health workers in primary healthcare
              centers to deliver quality Maternal and Child Health services.
            </li>
            <li>
              Conducting awareness programs in rural communities on safe
              motherhood.
            </li>
            <li>
              Providing home care follow-ups for pregnant women to ensure safe
              pregnancy and delivery outcomes.
            </li>
            <li>
              Training Traditional Birth Attendants to provide services to
              pregnant women.
            </li>
            <li>
              Sensitizing communities on the effects of harmful practices during
              pregnancy and childbirth.
            </li>
            <li>
              Advocating for male involvement in Maternal and Child Health.
            </li>
            <li>
              Enlightening women in rural communities on the importance of
              attending antenatal care (ANC) and delivering at health centers.
            </li>
            <li>
              Carrying out campaigns against Female Genital Mutilation and early
              child marriages.
            </li>
          </ul>

          <h2>Our Core Values and Guiding Principles</h2>
          <p>
            TRAFFINA Foundation believes in a society where the right government
            policies and programs are centered on developing the potential of
            children, women, and young persons, and harnessing them to be
            positive change agents.
          </p>
          <ul>
            <li>
              We believe people are the change agents in the society where they
              live.
            </li>
            <li>Respect for the dignity of life of individuals.</li>
            <li>Full support for community and family values.</li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default MissionVision;
