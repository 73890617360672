import React from "react";
import "./Hero.css";
import { Carousel } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { galleries } from "../../utils/data";

function Hero() {
  return (
    <div className="container-fluid px-0 px-md-5">
      <Carousel interval={3000} controls={true} indicators={true}>
        {galleries.map((gallery, index) => (
          <Carousel.Item key={index}>
            <div
              className="hero d-flex align-items-center justify-content-center text-white py-5"
              style={{
                backgroundImage: `url(${gallery.image})`,
                backgroundSize: "cover",
              }}
            >
              <div className="hero-overlay">
                <h6 className="fs-3 fs-md-5">{gallery.title}</h6>
                {/* <div className="mt-4">
                  <Link
                    to={gallery.link}
                    className="btn btn-light btn-md"
                    style={{
                      borderRadius: "50px",
                      color: "#00715D",
                      backgroundColor: "#FFFFFF",
                      fontSize: "16px",
                      padding: "10px 20px",
                    }}
                  >
                    Donate Now
                  </Link>
                </div> */}
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default Hero;
