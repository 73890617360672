import React, { useEffect, useState } from "react";
import Banner from "../../components/Banner/Banner";

function Projects() {
  const [donationAmount, setDonationAmount] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Scroll to a specific section if the URL contains a hash
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, []);

  const handleProjects = (e) => {
    e.preventDefault();
    alert(
      `Thank you for donating ${
        donationAmount ? `₦${donationAmount}` : selectedOption
      }!`
    );
  };

  return (
    <div>
      <Banner
        title="Our Projects"
        description="We're committed to improving maternal health and saving lives through innovative projects."
      />
      <div
        className="container-fluid px-4 px-md-5"
        style={{ marginTop: "100px", marginBottom: "100px" }}
      >
        {/* First Project - Let's Save Our Mothers */}
        <section className="row align-items-center mb-5">
          <div className="col-md-6 order-md-2">
            <img
              src="/images/38043140_1812835445419428_8856095265816313856_o.jpg"
              alt="Let's Save Our Mothers"
              className="img-fluid rounded shadow-lg"
            />
          </div>
          <div className="col-md-6 order-md-1">
            <h3 className="mb-3">Let's Save Our Mothers</h3>
            <p>
              In 2012, TFCH won a global grant challenge for Nurse Leaders. The
              founder, a Nurse Midwife, applied with the "Let's Save Our
              Mothers" project, aimed at saving women from harmful cultural
              practices during childbirth. The project reached 12 rural
              communities in Nigeria.
            </p>
            <p>
              These practices included giving birth alone, using animal bones as
              delivery tools, and inserting herbs to influence the baby’s sex.
              TFCH's advocacies raised awareness and provided safer delivery
              solutions.
            </p>
            <div className="text-muted mb-3">
              <span className="text-primary">Awarded in 2012</span> |{" "}
              <span className="text-success">12 Communities Impacted</span>
            </div>
            {/* <button
              className="btn btn-primary btn-sm"
              onClick={() => alert("Read More Coming Soon!")}
            >
              Read More
            </button> */}
          </div>
        </section>

        {/* Second Project - One Kit Saves 2 Lives */}
        <section className="row align-items-center mb-5">
          <div className="col-md-6">
            <img
              src="/images/chinomnso-and-her-team sensitizing-&-educationg-community-women-safe-motherhood and-utilization-of-the-birth-kits.jpg"
              alt="One-Kit-Saves-2-Lives"
              className="img-fluid rounded shadow-lg"
            />
          </div>
          <div className="col-md-6">
            <h3 className="mb-3">One Kit Saves 2 Lives</h3>
            <p>
              The "1 Kit Saves 2 Lives" project launched in 2014 to address the
              high maternal mortality rate in Nigeria. By producing and
              distributing delivery kits containing 17 essential medical items,
              TFCH has saved over 130,000 lives.
            </p>
            <p>
              These kits provide sterile equipment, reduce infection risks, and
              manage bleeding, offering critical support to low-income women.
              Distributed across 28 states and communities in Cameroon, the
              project continues to save lives.
            </p>
            <div className="text-muted mb-3">
              <span className="text-primary">Ongoing Project</span> |{" "}
              <span className="text-success">130,000+ Lives Saved</span>
            </div>
            {/* <button
              className="btn btn-primary btn-sm"
              onClick={() => alert("Read More Coming Soon!")}
            >
              Read More
            </button> */}
          </div>
        </section>

        {/* New Project Section 3 - Birthing Right Project Nigeria */}
        <section className="row align-items-center mb-5">
          <div className="col-md-6 order-md-2">
            <img
              src="/images/birthing-right-nigeria.png"
              alt="Birthing Right Project Nigeria"
              className="img-fluid rounded shadow-lg"
            />
          </div>
          <div className="col-md-6 order-md-1">
            <h3 className="mb-3">Birthing Right Project Nigeria</h3>
            <p>
              In 2015, TFCH won a grant to support maternal, newborn, and child
              health for HIV-positive pregnant women and new mothers. This
              project empowered HIV-positive women to support each other and
              provided training on treatment adherence, nutrition care, and the
              prevention of mother-to-child transmission of HIV/AIDS.
            </p>
            <p>
              The project distributed free "Moms & Newborn Delivery Kits" to
              help these women during childbirth, giving them the confidence to
              be treated with dignity. It has saved the lives of 1,487 women and
              children.
            </p>
            <div className="text-muted mb-3">
              <span className="text-primary">Supported by ICW West Africa</span>{" "}
              | <span className="text-success">1,487 Lives Saved</span>
            </div>
          </div>
        </section>

        {/* New Project Section 4 - Etisalat Traffina Foundation Community Maternal Health Project */}
        <section className="row align-items-center mb-5">
          <div className="col-md-6">
            <img
              src="/images/kits-distribution-in-River-state.jpg"
              alt="Etisalat-Traffina-Foundation-Community-Maternal-Health-Project"
              className="img-fluid rounded shadow-lg"
            />
          </div>
          <div className="col-md-6">
            <h3 className="mb-3">
              Etisalat Traffina Foundation Community Maternal Health Project
            </h3>
            <p>
              In 2015, TFCH partnered with Etisalat to deliver a maternal
              healthcare program to over 600 women across 16 communities in
              southern Nigeria. This project provided free "Moms & Newborn
              Delivery Kits" and critical maternal health information, helping
              prevent maternal deaths and childbirth infections.
            </p>
            <div className="text-muted mb-3">
              <span className="text-primary">16 Communities Reached</span> |{" "}
              <span className="text-success">600 Women Benefited</span>
            </div>
          </div>
        </section>

        {/* New Project Section 5 - Free Medical Mission / Community Health Care */}
        <section className="row align-items-center mb-5">
          <div className="col-md-6 order-md-2">
            <img
              src="/images/free-medicals-5.jpg"
              alt="Free-Medical-Mission"
              className="img-fluid rounded shadow-lg"
            />
          </div>
          <div className="col-md-6 order-md-1">
            <h3 className="mb-3">
              Free Medical Mission / Community Health Care
            </h3>
            <p>
              Since 2007, TFCH has provided free medical services to underserved
              communities in Northern Nigeria. These services include antenatal
              care, eye and dental care, malaria treatment, immunizations, and
              more, impacting over 21,000 Nigerians across 35 communities.
            </p>
            <div className="text-muted mb-3">
              <span className="text-primary">21,000 People Served</span> |{" "}
              <span className="text-success">35 Communities Impacted</span>
            </div>
          </div>
        </section>

        {/* New Project Section 6 - The School of Husbands Project */}
        <section className="row align-items-center mb-5">
          <div className="col-md-6">
            <img
              src="/images/IMG_1764.jpg"
              alt="School of Husbands Project"
              className="img-fluid rounded shadow-lg"
            />
          </div>
          <div className="col-md-6">
            <h3 className="mb-3">The School of Husbands Project</h3>
            <p>
              This program encourages men to support their wives during
              pregnancy and childbirth. In Bwari, 3,000 men have been engaged to
              improve maternal health outcomes through active involvement in
              antenatal care and HIV counseling.
            </p>
            <div className="text-muted mb-3">
              <span className="text-primary">3,000 Men Engaged</span> |{" "}
              <span className="text-success">
                Improved Maternal Health Outcomes
              </span>
            </div>
          </div>
        </section>

        {/* New Project Section 7 - Care Project for Internally Displaced Persons */}
        <section className="row align-items-center">
          <div className="col-md-6 order-md-2">
            <img
              src="/images/IDP-care.jpg"
              alt="Care Project for Internally Displaced Persons"
              className="img-fluid rounded shadow-lg"
            />
          </div>
          <div className="col-md-6 order-md-1">
            <h3 className="mb-3">
              Care Project for Internally Displaced Persons
            </h3>
            <p>
              TFCH provides essential support to displaced families in Abuja,
              including free medical services, educational support,
              psychological care, and food distribution. This project also
              offers antenatal care for pregnant women and sanitary care for
              girls and women.
            </p>
            <div className="text-muted mb-3">
              <span className="text-primary">Ongoing Support for IDPs</span>
            </div>
          </div>
        </section>

        {/* Donation Section */}
        {/* <section className="text-center">
          <h4 className="mb-4">Support Our Cause</h4>
          <form onSubmit={handleProjects}>
            <div className="mb-3">
              <input
                type="number"
                value={donationAmount}
                onChange={(e) => setDonationAmount(e.target.value)}
                placeholder="Enter donation amount (₦)"
                className="form-control"
              />
            </div>
            <button type="submit" className="btn btn-success">
              Donate {donationAmount && `₦${donationAmount}`}
            </button>
          </form>
        </section> */}
      </div>
    </div>
  );
}

export default Projects;
