import React from "react";
import "./About.css";
import { Link } from "react-router-dom";

function About() {
  return (
    <div
      className="container-fluid px-3 px-md-5"
      style={{
        marginTop: "100px",
      }}
    >
      <div className="row align-items-center">
        {/* Left column with text content */}
        <div className="col-md-6">
          <h2>About Us</h2>
          <p>
            Traffina Foundation over the past ten years has consistently
            provided maternal newborn and child health care support in 28 states
            in Nigeria including the state affected by insurgency. We are
            thankful to our staff and pool of volunteers across the states
            reaching the unreach and helping Nigerian women stay alive at time
            of pregnancy, during and after childbirth.
          </p>
          <Link
            to="/about-us"
            className="btn my-3"
            style={{
              color: "#FFFFFF",
              backgroundColor: "#00715D",
              borderRadius: "50px",
              padding: "10px 50px",
            }}
          >
            Read More
          </Link>
        </div>

        {/* Right column with YouTube video */}
        <div className="col-md-6 mb-4 mb-md-0">
          <div className="video-container">
            <iframe
              className="video-iframe"
              src="https://www.youtube.com/embed/nZh_J7SREuU"
              title="YouTube video"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
