import React, { useEffect } from "react";
import Banner from "../../components/Banner/Banner";

function Story() {
  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Get the current URL
    const hash = window.location.hash;

    if (hash) {
      // Scroll to the specific section
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, []);
  return (
    <>
      <Banner title="Our Story" />
      <div
        className="container-xl px-3 px-md-5"
        style={{
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        <div>
          <h2>Foundation Beginnings: The Founders Story:</h2>
          <p>
            Anticipating a baby’s birth should bring great joy and excitement.
            But for many African women, pregnancy is a time filled with anxiety
            and fear. Because of inadequate birthing supplies and limited access
            to proper care, pregnancy often brings unbearable pain and many
            times death for their baby, themselves, or both.
          </p>

          <p>
            Hearing these stories while growing up shaped my life and influenced
            my career choice of nursing and midwifery. Each time I heard about
            the death of a pregnant woman or newborn in our community, I became
            burdened with solving problems associated with childbirth and
            helping women and babies survive it.
          </p>

          <p>
            In 2005, as a nursing student with limited funds, I founded the
            Traffina Foundation for Community Health. The purpose of this
            foundation is to develop programs on safe motherhood, provide free
            medical supplies and care, and advocate for a government commitment
            to save our women and children. In 2009, I registered Traffina as an
            official organization.
          </p>

          <p>
            Over the past ten years, Traffina has made significant progress.
            Among its accomplishments:
          </p>
          <ul>
            <li>
              Free medical care for 37,578 African women in 35 rural
              communities.
            </li>
            <li>
              Free community prenatal care for 55,321 pregnant women so far.
            </li>
            <li>
              Advocacy to government to make childbirth safe and free of cost.
            </li>
            <li>Training for midwives.</li>
            <li>Donations of basic medical instruments to rural clinics.</li>
            <li>
              Production and free distribution of over 460,000 Mom &amp; Newborn
              Delivery Kits. Since 2014, these kits have alleviated childbirth
              complications and deaths, helping save the lives of more 920,000
              women and children.
            </li>
          </ul>

          <h2>Mom &amp; Newborn Delivery Kits</h2>
          <p>
            In Nigeria alone, 108 women die daily from preventable childbirth
            complications. These deaths, coupled with the deaths of children
            under five years of age, make the country the second largest
            contributor to the under-five and maternal mortality rate in the
            world. Greatly impacting these deaths are infections occurring
            during childbirth. These infections are often the result of unclean
            environments—including those of hospitals, clinics, and delivery
            rooms where women might lie on blood-soaked tables soiled by a
            previous delivery. Infections also come from contaminated supplies
            and unsafe instruments such as rusty forceps or broken bottles or
            unclean blades used to cut umbilical cords.
          </p>
          <p>
            Coupled with the lack of cleanliness is the local midwives’ lack of
            health information and their use of unsafe practices. In most cases,
            these midwives—whom rural, poverty-stricken women patronize—employ
            “traditional” delivery methods. These might include using their
            mouths to suck out the mucus from newborns, exposing both the babies
            and themselves to infection, or inserting local herbs into the
            perineum if heavy bleeding occurs.
          </p>
          <p>
            The fact that millions of African women and newborns die every year
            from preventable infections led the Trafinna Foundation for
            Community Health to create the Mom &amp; Newborn Delivery Kits.
            These kits include tetanus medication as well as fifteen
            life-saving, child-birthing supplies such as sterile blades, aprons,
            surgical gloves, mucus extractors, and disposable mats.
          </p>
          <p>
            In addition to distributing the kits, the foundation utilizes
            technology to battle deaths related to childbirth. Every woman who
            receives a birth kit receives follow-up care and information related
            to prenatal care, nutrition, post-partum care, and immunization.
            This information, translated into various local languages, is
            broadcast via SMS and voice call. Those who have access to the
            internet can connect weekly to our live Mama &amp; Pikin Matter
            program. These prenatal classes reached over 35,645 viewers in a
            seven-month period. Midwives, as well, receive birth kits and health
            information via voice calls and the internet. In their local
            languages, they learn about the stages of labor, use of the birth
            kits, newborn care, signs of complications, and emergency
            procedures. They are also referred to sources they might need for
            additional advice.
          </p>
          <p>
            The Mom &amp; Newborn Delivery Kits provide viable options for women
            who once had none. Previously, rural women who couldn’t provide
            their own delivery materials were turned away from hospitals and
            clinics. This left them with the undesirable choices of either
            giving birth by themselves at home or patronizing dirty and
            dangerous birth homes. Poor, uneducated women in rural areas fared
            no better. With no decent roads or other access to cities and health
            care, they faced the same miserable choices. But the kits and the
            follow-up that accompanies them have changed this sad situation for
            many women. Traffina Foundation’s extensive distribution network
            ensures that women in Nigeria—even those in the most remote
            parts—receive the pregnancy and birthing care they deserve.
          </p>

          <h2>Goals and Expectations</h2>
          <p>
            The Traffina Foundation looks forward to the time when poor African
            women can walk into hospitals with their birth kits and be treated
            with dignity and respect. It dreams of the day when all midwives are
            trained to deliver excellent care and are equipped with the
            necessary tools to do so. We have seen many lives saved through this
            program, and we know the happiness and relief our kits and care
            bring. But we will not consider our mission complete until every
            expectant mother can enjoy a pregnancy free from undue worry and
            fear and can experience the joy of delivering a healthy baby.
          </p>
        </div>
      </div>
    </>
  );
}

export default Story;
