// import React from "react";
// import "./Home.css"; // Custom CSS for styling
// import { Link } from "react-router-dom";

// function Home() {
//   return (
//     <>
//       {/* Hero Section */}
//       {/* <div>
//         <div className="hero d-flex align-items-center justify-content-center text-white py-5">
//           <div className="hero-overlay">
//             <h6 className="fs-5 fs-md-3">
//               Your support means a lot to the World
//             </h6>
//             <div className="mt-4">
//               <Link
//                 to="/donate"
//                 className="btn btn-light btn-md"
//                 style={{
//                   borderRadius: "0px",
//                   color: "#00715D",
//                 }}
//               >
//                 Donate Now
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div> */}

//       {/* Hero */}
//       <div>
//         <div className="container-fluid px-0 px-md-5">
//           <div className="hero">
//             <div className="hero-overlay">
//               <h6 className="fs-5 fs-md-3">
//                 Your support means a lot to the World
//               </h6>
//               <div className="mt-4">
//                 <Link
//                   to="/donate"
//                   className="btn btn-light btn-md"
//                   style={{
//                     borderRadius: "0px",
//                     color: "#00715D",
//                   }}
//                 >
//                   Donate Now
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div>
//         <div className="container-xl my-5">
//           <div className="row justify-content-end">
//             {/* Card 1 */}
//             <div className="col-md-4 mb-4">
//               <div
//                 className="card text-white"
//                 style={{
//                   backgroundColor: "#00715D",
//                   borderRadius: "0px 0px 15px 0px",
//                 }}
//               >
//                 <div className="card-body">
//                   <div className="d-flex align-items-center mb-3">
//                     {/* <FaIconName1 size={24} className="me-2" /> */}
//                     <h5 className="card-title mb-0">Card Title 1</h5>
//                   </div>
//                   <p className="card-text">
//                     This is a brief description for the first card. It explains
//                     what this card is about.
//                   </p>
//                   <div className="d-flex align-items-center justify-content-end">
//                     <Link
//                       to=""
//                       className="btn bg-white"
//                       style={{
//                         color: "#00715D",
//                         borderRadius: "0px 0px 15px 0px",
//                       }}
//                     >
//                       View More
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* Card 2 */}
//             <div className="col-md-4 mb-4">
//               <div
//                 className="card text-white"
//                 style={{
//                   backgroundColor: "#00715D",
//                   borderRadius: "0px 0px 15px 0px",
//                 }}
//               >
//                 <div className="card-body">
//                   <div className="d-flex align-items-center mb-3">
//                     {/* <FaIconName2 size={24} className="me-2" /> */}
//                     <h5 className="card-title mb-0">Card Title 2</h5>
//                   </div>
//                   <p className="card-text">
//                     This is a brief description for the second card. It explains
//                     what this card is about.
//                   </p>
//                   <div className="d-flex align-items-center justify-content-end">
//                     <Link
//                       to=""
//                       className="btn bg-white"
//                       style={{
//                         color: "#00715D",
//                         borderRadius: "0px 0px 15px 0px",
//                       }}
//                     >
//                       View More
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* Card 3 */}
//             <div className="col-md-4 mb-4">
//               <div
//                 className="card text-white"
//                 style={{
//                   backgroundColor: "#00715D",
//                   borderRadius: "0px 0px 15px 0px",
//                 }}
//               >
//                 <div className="card-body">
//                   <div className="d-flex align-items-center mb-3">
//                     {/* <FaIconName3 size={24} className="me-2" /> */}
//                     <h5 className="card-title mb-0">Card Title 3</h5>
//                   </div>
//                   <p className="card-text">
//                     This is a brief description for the third card. It explains
//                     what this card is about.
//                   </p>
//                   <div className="d-flex align-items-center justify-content-end">
//                     <Link
//                       to=""
//                       className="btn bg-white"
//                       style={{
//                         color: "#00715D",
//                         borderRadius: "0px 0px 15px 0px",
//                       }}
//                     >
//                       View More
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* Card 3 */}
//             <div className="col-md-4 mb-4">
//               <div
//                 className="card text-white"
//                 style={{
//                   backgroundColor: "#00715D",
//                   borderRadius: "0px 0px 15px 0px",
//                 }}
//               >
//                 <div className="card-body">
//                   <div className="d-flex align-items-center mb-3">
//                     {/* <FaIconName3 size={24} className="me-2" /> */}
//                     <h5 className="card-title mb-0">Card Title 3</h5>
//                   </div>
//                   <p className="card-text">
//                     This is a brief description for the third card. It explains
//                     what this card is about.
//                   </p>
//                   <div className="d-flex align-items-center justify-content-end">
//                     <Link
//                       to=""
//                       className="btn bg-white"
//                       style={{
//                         color: "#00715D",
//                         borderRadius: "0px 0px 15px 0px",
//                       }}
//                     >
//                       View More
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* Card 3 */}
//             <div className="col-md-4 mb-4">
//               <div
//                 className="card text-white"
//                 style={{
//                   backgroundColor: "#00715D",
//                   borderRadius: "0px 0px 15px 0px",
//                 }}
//               >
//                 <div className="card-body">
//                   <div className="d-flex align-items-center mb-3">
//                     {/* <FaIconName3 size={24} className="me-2" /> */}
//                     <h5 className="card-title mb-0">Card Title 3</h5>
//                   </div>
//                   <p className="card-text">
//                     This is a brief description for the third card. It explains
//                     what this card is about.
//                   </p>
//                   <div className="d-flex align-items-center justify-content-end">
//                     <Link
//                       to=""
//                       className="btn bg-white"
//                       style={{
//                         color: "#00715D",
//                         borderRadius: "0px 0px 15px 0px",
//                       }}
//                     >
//                       View More
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* Card 3 */}
//             <div className="col-md-4 mb-4">
//               <div
//                 className="card text-white"
//                 style={{
//                   backgroundColor: "#00715D",
//                   borderRadius: "0px 0px 15px 0px",
//                 }}
//               >
//                 <div className="card-body">
//                   <div className="d-flex align-items-center mb-3">
//                     {/* <FaIconName3 size={24} className="me-2" /> */}
//                     <h5 className="card-title mb-0">Card Title 3</h5>
//                   </div>
//                   <p className="card-text">
//                     This is a brief description for the third card. It explains
//                     what this card is about.
//                   </p>
//                   <div className="d-flex align-items-center justify-content-end">
//                     <Link
//                       to=""
//                       className="btn bg-white"
//                       style={{
//                         color: "#00715D",
//                         borderRadius: "0px 0px 15px 0px",
//                       }}
//                     >
//                       View More
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Home;

// import React from "react";
// import { Link } from "react-router-dom";
// import Banner from "../../assets/Images/10533889_721832277853089_1277417385195566194_o.jpg";

// function Home() {
//   return (
//     <>
//       {/* Hero Section */}
//       <div className="container-fluid p-0">
//         <div
//           id="heroCarousel"
//           className="carousel slide"
//           data-bs-ride="carousel"
//         >
//           <div className="carousel-inner">
//             <div className="carousel-item active">
//               <div
//                 className="hero d-flex align-items-center justify-content-center text-white"
//                 style={{
//                   backgroundImage: `url(${Banner})`, // Use the imported image as background
//                   backgroundSize: "cover",
//                   backgroundPosition: "center",
//                   height: "100vh",
//                   position: "relative",
//                 }}
//               >
//                 <div className="hero-overlay text-center">
//                   <h2 className="display-4">Your Support Means a Lot</h2>
//                   <p className="lead">Help us make the world a better place.</p>
//                   <Link to="/donate" className="btn btn-lg btn-light mt-3">
//                     Donate Now
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* About Section */}
//       <section className="py-5 bg-light">
//         <div className="container">
//           <div className="row text-center">
//             <div className="col">
//               <h3>Our Mission</h3>
//               <p>
//                 We aim to provide essential services to those in need,
//                 empowering them to build a better future.
//               </p>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Card Section */}
//       <section className="py-5">
//         <div className="container">
//           <div className="row g-4">
//             <div className="col-md-4">
//               <div className="card h-100 shadow">
//                 <img
//                   src="/path-to-image1.jpg"
//                   className="card-img-top"
//                   alt="Card 1"
//                 />
//                 <div className="card-body">
//                   <h5 className="card-title">Card Title 1</h5>
//                   <p className="card-text">
//                     This is a brief description for the first card. It explains
//                     what this card is about.
//                   </p>
//                   <Link to="" className="btn btn-primary">
//                     View More
//                   </Link>
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-4">
//               <div className="card h-100 shadow">
//                 <img
//                   src="/path-to-image2.jpg"
//                   className="card-img-top"
//                   alt="Card 2"
//                 />
//                 <div className="card-body">
//                   <h5 className="card-title">Card Title 2</h5>
//                   <p className="card-text">
//                     This is a brief description for the second card. It explains
//                     what this card is about.
//                   </p>
//                   <Link to="" className="btn btn-primary">
//                     View More
//                   </Link>
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-4">
//               <div className="card h-100 shadow">
//                 <img
//                   src="/path-to-image3.jpg"
//                   className="card-img-top"
//                   alt="Card 3"
//                 />
//                 <div className="card-body">
//                   <h5 className="card-title">Card Title 3</h5>
//                   <p className="card-text">
//                     This is a brief description for the third card. It explains
//                     what this card is about.
//                   </p>
//                   <Link to="" className="btn btn-primary">
//                     View More
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// }

// export default Home;

import React, { useEffect } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import Hero from "../../components/Hero/Hero";
import Features from "../../components/Features/Features";
import Newsletter from "../../components/Newsletter/Newsletter";
import About from "../../components/About/About";
import Blogs from "../../components/Blogs/Blogs";
import Gallery from "../../components/Gallery/Gallery";

function Home() {
  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);
  }, []);

  const datas = [
    {
      id: "donate",
      title: "Your support will impact lives",
      description:
        "When you give to Traffina Foundation, you fight against the barriers that women and childern face every day.",
      btn: "Donate Now",
      image: "/images/colorful kits.jpg",
      link: "/donate",
    },
    {
      id: "volunteer",
      title: "Together we can change lives",
      description:
        "When you give support to Traffina Foundation, you fight against the barriers that women and childern face every day.",
      btn: "Become a Volunteer",
      image: "/images/TFCH outreach in kogo with banner.jpg",
      link: "/volunteer",
    },
  ];
  return (
    <>
      {/* Hero Section */}
      <Hero />

      {/* About Section */}
      <About />

      {/* Partner Section */}
      {/* <Partner /> */}

      {/* Cards Section */}
      <Features />

      {/* Use react-fast-marquee here to create moving galleries with pictures on it  */}
      <Gallery />

      <div>
        <div
          className="container-xl"
          style={{
            backgroundColor: "#EBFBF7",
            marginTop: "100px",
            padding: "50px",
          }}
        >
          <h2>Here’s how you can help!</h2>
          <p>
            From advocacy, to donating money, volunteering and more, there are
            many ways you can help.
          </p>
          <div className="row g-4">
            {datas.map((data, index) => (
              <div className="col-md-6" key={index}>
                <div className="card mb-4 h-100">
                  <img
                    src={data.image}
                    className="card-img-top"
                    alt={data.title}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{data.title}</h5>
                    <p className="card-text">{data.description}</p>
                    <Link
                      to={data.link}
                      className="btn"
                      style={{
                        color: "#FFFFFF",
                        backgroundColor: "#00715D",
                        borderRadius: "0px",
                        width: "100%",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {data.btn}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* News */}
      {/* <News /> */}

      {/* Blogs */}
      <Blogs />

      {/* Testimonial Section */}
      {/* <Testimonial /> */}

      {/* Contact */}
      {/* <Contact /> */}

      {/* FightPoverty */}
      {/* <FightPoverty /> */}

      {/* Newsletter */}
      <Newsletter />
    </>
  );
}

export default Home;
