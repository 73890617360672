import React from "react";
import { Link } from "react-router-dom";
import "./Features.css"; // Optional, if you need custom styles

function Features() {
  return (
    <div
      className="container-fluid px-3 px-md-5"
      style={{
        marginTop: "100px",
      }}
    >
      <div className="row justify-content-center">
        {/* Card 1 */}
        <div className="col-md-3 mb-4">
          <div
            className="card text-white d-flex flex-column"
            style={{
              backgroundColor: "#00715D",
              borderRadius: "0px",
              height: "100%",
            }}
          >
            <div className="card-body d-flex flex-column text-center">
              <div className="icon-container mb-3">
                <i
                  className="bi bi-hand-thumbs-up"
                  style={{
                    fontSize: "48px",
                    display: "block",
                    margin: "0 auto",
                  }}
                ></i>
              </div>
              <h5 className="card-title">We're efficient.</h5>
              <p className="card-text flex-grow-1">
                Your donation does more when you give it to Traffina Foundation. 90% of the
                money Traffina Foundation spends goes toward our lifesaving work that saves and
                transforms lives.
              </p>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div className="col-md-3 mb-4">
          <div
            className="card text-white d-flex flex-column"
            style={{
              backgroundColor: "#00715D",
              borderRadius: "0px",
              height: "100%",
            }}
          >
            <div className="card-body d-flex flex-column text-center">
              <div className="icon-container mb-3">
                <i
                  className="bi bi-globe"
                  style={{
                    fontSize: "48px",
                    display: "block",
                    margin: "0 auto",
                  }}
                ></i>
              </div>
              <h5 className="card-title">We're far-reaching.</h5>
              <p className="card-text flex-grow-1">
                We are one of the largest networks of humanitarians in the
                world, working in 109 countries.
              </p>
            </div>
          </div>
        </div>

        {/* Card 3 */}
        <div className="col-md-3 mb-4">
          <div
            className="card text-white d-flex flex-column"
            style={{
              backgroundColor: "#00715D",
              borderRadius: "0px",
              height: "100%",
            }}
          >
            <div className="card-body d-flex flex-column text-center">
              <div className="icon-container mb-3">
                <i
                  className="bi-arrows-move"
                  style={{
                    fontSize: "48px",
                    display: "block",
                    margin: "0 auto",
                  }}
                ></i>
              </div>
              <h5 className="card-title">We're locally led. </h5>
              <p className="card-text flex-grow-1">
                97% of our staff are from the countries or regions where we
                work. This means we can respond as soon as emergencies happen,
                and we stay long after the TV cameras leave.
              </p>
            </div>
          </div>
        </div>

        {/* Card 4 */}
        <div className="col-md-3 mb-4">
          <div
            className="card text-white d-flex flex-column"
            style={{
              backgroundColor: "#00715D",
              borderRadius: "0px",
              height: "100%",
            }}
          >
            <div className="card-body d-flex flex-column text-center">
              <div className="icon-container mb-3">
                <i
                  className="bi bi-shield-check"
                  style={{
                    fontSize: "48px",
                    display: "block",
                    margin: "0 auto",
                  }}
                ></i>
              </div>
              <h5 className="card-title">We're trusted.</h5>
              <p className="card-text flex-grow-1">
                With nearly 80 years of service, Traffina Foundation has a history of building
                community trust. We listen to what people want and develop
                solutions they need.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
