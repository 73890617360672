export const blogsList = [
  {
    id: 1,
    title: "Empowering Mothers: The Heart of Traffina Foundation",
    description:
      "Traffina Foundation is on a mission to transform maternal healthcare in Nigeria, providing life-saving support to mothers through accessible healthcare services, educational outreach, and clean delivery kits. Our work empowers mothers to experience safer pregnancies and deliveries, even in remote communities where healthcare resources are limited. Join us in exploring the vital role we play in uplifting maternal health and supporting family well-being across Nigeria.",
    image: "/images/community-Maternal-health-outreach-2.jpg",
    data: [
      "Ensuring Safe Childbirth Experiences: Through our initiative, thousands of expectant mothers receive clean delivery kits, reducing the risk of infections and complications during childbirth. Each kit is packed with essential supplies, such as sterile gloves and umbilical cord clamps, that empower mothers to bring their newborns into the world safely.",
      "Door-to-Door Antenatal Care: Recognizing that many mothers in rural regions struggle to access health facilities, our team provides door-to-door antenatal care services. This personalized care extends critical health monitoring and counseling directly to mothers, promoting early detection of potential complications and empowering them with the knowledge to maintain a healthy pregnancy.",
      "Community Education for Sustainable Health: We believe that educating communities about maternal health creates lasting change. Our outreach programs teach families and communities about prenatal care, the importance of skilled birth attendants, and early childhood health, fostering a sustainable impact that reaches beyond the individual to create a culture of health and support.",
    ],
  },
  {
    id: 2,
    title: "Supporting Child Health Initiatives Across Nigeria",
    description:
      "At Traffina Foundation, we are committed to improving the lives of children in underserved communities, providing free medical care and preventive health programs designed to combat common childhood illnesses and improve overall well-being. Our initiatives not only offer vital healthcare access but also raise awareness and promote long-term health for the next generation.",
    image: "/images/free-medicals-4.jpg",
    data: [
      "Free Medical Services for Children: Through our outreach clinics, we bring essential healthcare services to children in rural and underserved areas. From treating common infections to providing vaccinations, our goal is to ensure that every child has a chance at a healthy start in life, regardless of their family's financial situation.",
      "Community Engagement and Awareness Programs: Beyond direct medical care, we engage communities in health education programs that emphasize preventive care for children. Through these programs, parents and caregivers learn how to recognize symptoms of common illnesses, understand basic hygiene practices, and support their children's health proactively.",
      "Collaborative Efforts for Greater Reach: We partner with local organizations, schools, and community leaders to expand our reach, ensuring that children in even the most remote areas have access to health resources. By building a network of committed partners, we are able to create a robust support system for child health that resonates across communities.",
    ],
  },
  {
    id: 3,
    title: "Building Healthier Communities with Traffina Foundation",
    description:
      "Healthier communities are the foundation of a prosperous society, and at Traffina Foundation, we are dedicated to making healthcare accessible for all. By fostering a collaborative network of healthcare professionals, advocating for family involvement, and training community health workers, we are transforming the healthcare landscape in Nigeria. Explore our efforts in creating a sustainable healthcare network that uplifts both mothers and children across the nation.",
    image: "/images/work-in-kebbi-state.png",
    data: [
      "Training Traditional Birth Attendants (TBAs): Traditional Birth Attendants are often the first point of contact for pregnant women in rural communities. We provide TBAs with comprehensive training on safe delivery practices, basic neonatal care, and hygiene, helping bridge the gap between traditional and modern healthcare practices and significantly improving birth outcomes.",
      "Encouraging Male Involvement in Health: We advocate for and promote male involvement in maternal and child health, recognizing that supportive fathers and family members play a critical role in achieving positive health outcomes. Through workshops and community discussions, we help break down cultural barriers and encourage men to take an active role in family health, fostering a stronger, more unified approach to care.",
      "Strengthening Healthcare Through Partnerships: Traffina Foundation collaborates closely with government health departments, NGOs, and local health facilities to create a connected, resilient healthcare system. By pooling resources, sharing knowledge, and aligning our efforts, we are building a network that makes healthcare more reliable and accessible, ensuring that no one is left behind in the journey to better health.",
    ],
  },
];

export const galleries = [
  {
    title: "Chinomnso Educating Women",
    image: "/images/Chinomnso-Educating-women.jpg",
    link: "/volunteer",
  },
  {
    title: "Clean Birth Kit Support In Abaji",
    image: "/images/Clean-Birth-Kit-support-in-Abaji.jpg",
    link: "/donate",
  },
  {
    title: "Community Immunization Outreach In Jigo",
    image: "/images/TFCH-community-immunization-outreach-in-Jigo.jpg",
    link: "/volunteer",
  },
  {
    title: "Having the support of community heads to promote maternal health",
    image:
      "/images/having-the-support-of-community-heads-to-promote-maternal-health.jpg",
    link: "/donate",
  },
  {
    title: "Kits Distribution In River State",
    image: "/images/kits-distribution-in-River-state.jpg",
    link: "/volunteer",
  },
  {
    title: "Community Maternal health outreach 2",
    image: "/images/community-Maternal-health-outreach-2.jpg",
    link: "/donate",
  },
  {
    title: "Health outreach for women",
    image: "/images/health-outreach-for-women.jpg",
    link: "/volunteer",
  },
  {
    title: "Promoting maternal health in Kogo community",
    image: "/images/TFCH-promoting-maternal-health-in-Kogo-community.jpg",
    link: "/donate",
  },
  {
    title: "Community women happily possing with the clean delivery kits",
    image:
      "/images/community-women-happily-possing-with-the clean-delivery-kits.jpg",
    link: "/volunteer",
  },
  {
    title: "Configuration moment",
    image: "/images/10533889_721832277853089_1277417385195566194_o.jpg",
    link: "/donate",
  },
];

export const imageData = [
  {
    src: "/images/905775_815178315185151_3924683543864039372_o.jpg",
    alt: "Image",
  },
  {
    src: "/images/10533889_721832277853089_1277417385195566194_o.jpg",
    alt: "Image",
  },
  {
    src: "/images/11024215_815178148518501_6440608891823333722_n.jpg",
    alt: "Image",
  },
  {
    src: "/images/11130385_852203878149261_2081439640841566329_o.jpg",
    alt: "Image",
  },
  {
    src: "/images/11150817_830938096942506_7156641511173002214_n.jpg",
    alt: "Image",
  },
  {
    src: "/images/for-front-page-slides-2.jpg",
    alt: "Image",
  },
  {
    src: "/images/38043140_1812835445419428_8856095265816313856_o.jpg",
    alt: "Image",
  },
  {
    src: "/images/training-our-midwives-on-use-of-dopplers.jpg",
    alt: "Image",
  },
];
