import "./Blogs.css";

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { blogsList } from "../../utils/data";

function Blogs() {
  return (
    <div
      className="container-fluid px-3 px-md-5"
      style={{
        marginTop: "100px",
        marginBottom: "100px",
      }}
    >
      <h2>Latest Blogs</h2>
      <div className="row">
        {blogsList.map((blogsItem, index) => (
          <div className="col-md-4" key={index}>
            <div className="card mb-4 rounded-0 h-100">
              <img
                src={blogsItem.image}
                className="card-img-top rounded-0"
                alt={blogsItem.title}
                style={{
                  height: "230px",
                }}
              />
              <div className="card-body">
                <h5 className="card-title">{blogsItem.title}</h5>
                <p className="card-text">{blogsItem.description}</p>
                <Link
                  to={`/blogs/${blogsItem.id}`}
                  className="btn"
                  style={{
                    color: "#FFFFFF",
                    backgroundColor: "#00715D",
                    borderRadius: "0px",
                    width: "100%",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Blogs;
