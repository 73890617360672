import React, { useEffect } from "react";
import "./Blogs.css";
import Banner from "../../components/Banner/Banner";

import Image from "../../assets/Images/Image-17.webp";
import { Link } from "react-router-dom";
import { blogsList } from "../../utils/data";

function Blogs() {
  // Scroll to the top on component load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Scroll to specific section if URL contains hash
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, []);

  return (
    <>
      <Banner title="Our Blogs" description="Gain insights and inspiration" />
      <div
        className="container-fluid px-3 px-md-5"
        style={{
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        <div className="row">
          {blogsList.map((blogsItem, index) => (
            <div className="col-md-4" key={index}>
              <div className="card mb-4 rounded-0 h-100">
                <img
                  src={blogsItem.image}
                  className="card-img-top rounded-0"
                  style={{
                    height: "230px",
                  }}
                  alt={blogsItem.title}
                />
                <div className="card-body">
                  <h5 className="card-title">{blogsItem.title}</h5>
                  <p className="card-text">{blogsItem.description}</p>
                  <Link
                    to={`/blogs/${blogsItem.id}`}
                    className="btn"
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: "#00715D",
                      borderRadius: "0px",
                      width: "100%",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Blogs;
